export const dataCIndex = [
  {
    id: 1,
    imgUrl: "distritacion.jpg",
    alt: "Distritación Electoral Local Tlaxcala 2022",
    url: "",
    linkToo: "/Mapas",
  },
  {
    id: 2,
    imgUrl: "guiaViolencia.jpg",
    alt: "Guia de actuación",
    url: "https://itetlax.org.mx/assets/pdf/acuerdos/ITE/2022/64.1.pdf",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "libroITE.jpg",
    alt: "Libro ITE",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroITE.pdf",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "libroOPPMT.jpg",
    alt: "Libro OPPMT",
    url: "https://itetlax.org.mx/assets/pdf/carousel/libroOPPMT.pdf",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "memElectoral.jpg",
    alt: "Memoria Electoral 2021",
    url: "https://itetlax.org.mx/assets/pdf/memElect/11.pdf",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "comunidades.jpeg",
    alt: "micrositioComunidades",
    url: "https://ite-comunidades.itetlax.org.mx",
    linkToo: "",
  },
  {
    id: 7,
    imgUrl: "ite-conecta.jpg",
    alt: "ITE Conecta",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    url: "",
    linkToo: "/ITEConecta",
  },
  {
    id: 8,
    imgUrl: "accionesAfirmativas.png",
    alt: "Acciones Afirmativas",
    url: "https://ite-consulta-pcd.itetlax.org.mx/",
    linkToo: "",
  },
  {
    id: 9,
    imgUrl: "conoceles_top.png",
    alt: "ITE Conecta",
    url: "https://conocelestlaxcala2024.org.mx/",
    linkToo: "",
  },
  {
    id: 9,
    imgUrl: "prep_top.png",
    alt: "ITE Conecta",
    url: "https://www.prep2024-tlax.org.mx/",
    linkToo: "",
  },
];

export const dataCAR = [
  {
    id: 1,
    imgUrl: "1NL.png",
    alt: "Convocatoria OPPMT",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "2NL.png",
    alt: "Convocatoria OPPMT",
    url: "",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "0.png",
    alt: "Convocatoria OPPMT",
    url: "",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "11.png",
    alt: "siceen21",
    url: "https://siceen21.ine.mx/home",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "apoyo_ciudadano.png",
    alt: "Convocatoria",
    url: "",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "infografia-paridad.png",
    alt: "Convocatoria",
    url: "",
    linkToo: "",
  },
  {
    id: 7,
    imgUrl: "concurso_iaip.jpg",
    alt: "Concurso IAPI",
    url: "https://concurso.inai.org.mx/plenoninos/",
    linkToo: "",
  },
  {
    id: 8,
    imgUrl: "consulta_infantil.jpg",
    alt: "Consulta Infantil",
    url: "https://ine.mx/consulta-infantil-y-juvenil-2024/",
    linkToo: "",
  },
  {
    id: 9,
    imgUrl: "concurso_radio.jpeg",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    alt: "Consulta Infantil",
    url: "https://itetlax.org.mx/assets/img/img_CAR/concurso_radio.pdf",
    linkToo: "",
  },
];

export const dataCA = [
  {
    id: 1,
    imgUrl: "7.jpg",
    alt: "Participación Política de las mujeres tlaxcaltecas",
    url: "",
    linkToo: "",
  },
  {
    id: 2,
    imgUrl: "46.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 3,
    imgUrl: "47.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 4,
    imgUrl: "48.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 5,
    imgUrl: "49.jpg",
    alt: "Faro Democrático",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
  {
    id: 6,
    imgUrl: "50.jpg",
    alt: "Faro Democrático",
    itemActive: "active",
    buttonActive: "active",
    ariaCurrent: "true",
    url: "https://farodemocratico.ine.mx/",
    linkToo: "",
  },
];

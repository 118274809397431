// ------------ A C U E R D O S    I T E ------------
export const dataAcuerdos2024 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE APRUEBA PROCESO TÉCNICO OPERATIVO DEL PREP PARA PELO 2023-2024",
    titleAnexo1: "PROCESO TÉCNICO OPERATIVO DEL PREP",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-071-2023 Y ACUMULADOS",
    titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
    titleAnexo2: "ANEXO UNO",
    titleAnexo3: "ANEXO DOS",
    titleAnexo4: "ANEXO TRES",
  },
  {
    id: 3,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "SE DESIGNA TITULAR DE LA DPAyF",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "POR EL QUE SE APRUEBA EL INFORME DE OTORGAMIENTO DE INCENTIVOS 2023 PERSONAL SPEN",
    titleAnexo1: "Informe otorgamiento incentivos",
  },
  {
    id: 5,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE ORDENA DIFUNDIR GASTO CALENDARIZADO PARA FINANCIAMIENTO DE PP 2024",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
    titleAnexo3: "ANEXO TRES",
  },
  {
    id: 6,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "SE ACTUALIZA CALENDARIZACIÓN DE CANTIDADES A RETENER PRI, MC Y PT",
    titleAnexo1: "ANEXO UNICO",
  },
  {
    id: 7,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "CALENDARIZACIÓN DE REMANENTES DE FINANCIAMIENTO PÚBLICO DE CAMPAÑA NO EJERCIDOS 2020-2021, ACUERDO INE-CG232-2023",
    titleAnexo1: "ANEXO UNICO",
  },
  {
    id: 8,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc:
      "SE DECLARA LA PROCEDENCIA RESPECTO DE MODIFICACIÓN DE DOCUMENTOS BÁSICOS NUEVA ALIANZA TLAXCALA",
  },
  {
    id: 9,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc:
      "SE NOMBRA A LA COORDINADORA DE GÉNERO Y NO DISCRIMINACIÓN DE ESTE INSTITUTO.",
  },
  {
    id: 10,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "CUMPLIMIENTO DE SENTENCIA DIPUTACIÓN MIGRANTE, CUMPLIMIENTO SENTENCIA TET-JDC-049-2023",
    titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024",
    titleAnexo2: "ANEXO UNO",
    titleAnexo3: "ANEXO DOS",
    titleAnexo4: "ANEXO TRES",
  },
  {
    id: 11,
    typeDoc: "RESOLUCION",
    monthDoc: "ENE",
    nameDoc: "REGISTRO CONVENIO DE COALICIÓN FUERZA Y CORAZON POR TLAXCALA",
  },
  {
    id: 12,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "CALENDARIZA LA RETENCIÓN DE MULTAS A LOS PARTIDOS POLÍTICOS",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 13,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "UBICACIÓN DE LOS CENTROS DE ACOPIO Y TRANSMISIÓN DE DATOS",
  },
  {
    id: 14,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc: "SE APRUEBA PROGRAMA DE SEMINARIOS, CONFERENCIAS, COLOQUIOS",
    titleAnexo1: "ANEXO ÚNICO. PROGRAMA",
  },
  {
    id: 15,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "CUMPLIMIENTO A LA SENTENCIA TET-JDC-30-2020 Y ACUMULADO TET-JDC-32-2020",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
  },
  {
    id: 16,
    typeDoc: "ACUERDO",
    monthDoc: "ENE",
    nameDoc:
      "INFORME FINAL DEL INTERVENTOR DEL OTRORA PARTIDO POLÍTICO LOCAL ENCUENTRO SOCIAL TLAXCALA",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 17,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ENE",
    nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-023-2023",
  },
  {
    id: 18,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "CUMPLIMIENTO A LA SENTENCIA TET-JE-071-2023",
    titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS",
    titleAnexo2: "ANEXO UNO",
    titleAnexo3: "ANEXO DOS",
    titleAnexo4: "ANEXO TRES",
  },
  {
    id: 19,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "AJUSTE FRL PLAZO DE VERIFICACIÓN DEL APOYO CIUDADANO PELO 23-24",
  },
  {
    id: 20,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "SE DESIGNA ENTE AUDITOR DEL PREP PELO 23-24",
  },
  {
    id: 21,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc: "POR EL QUE SE APRUEBA EL DICTAMEN DE DOCUMENTACIÓN CON EMBLEMAS",
    titleAnexo1: "ANEXO UNICO",
  },
  {
    id: 22,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE DA RESPUESTA A CONSULTAS DE ACCIONES A GRUPOS DE ATENCIÓN PRIORITARIA",
  },
  {
    id: 23,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE APRUEBA EL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    titleAnexo1:
      "ANEXO ÚNICO PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    titleAnexo2:
      "ANEXO UNO DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    titleAnexo3:
      "ANEXO DOS DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    titleAnexo4:
      "ANEXO TRES DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
    titleAnexo5:
      "ANEXO CUATRO DEL PROTOCOLO PARA LA DETECCIÓN, RECOLECCIÓN, ENTREGA E INTERCAMBIO DE PAQUETES PELO 2023-2024",
  },
  {
    id: 24,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE APRUEBA LA DESIGNACIÓN DE LOS LUGARES QUE OCUPARAN LAS BODEGAS ELECTORALES PELO 2023-2024",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 25,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE APRUEBAN LINEAMIENTOS DE DESARROLLO DE CÓMPUTO Y CUADERNILLO DE VOTOS PELO 2023-2024",
    titleAnexo1:
      "ANEXCO UNO LINEAMIENTOS PARA EL DESARROLLO DE LOS CÓMPUTOS ELECTORALES PELO 2023-2024",
    titleAnexo2:
      "ANEXO DOS CUADERNILLO DE CONSULTA DE VOTOS VÁLIDOS Y NULOES PELO 2023-2024",
  },
  {
    id: 26,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE CALENDARIZA MULTA PENDIENTE POR PAGAR AL PARTIDO MORENA",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 27,
    typeDoc: "ACUERDO",
    monthDoc: "FEB",
    nameDoc:
      "POR EL QUE SE DETERMINAN LOS TOPES DE GASTO DE CAMPAÑA EN EL PELO 2023-2024",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
    titleAnexo3: "ANEXO TRES",
  },
  //ACUERDO 28 MEDIDAS DE PROTECCION DE DATOS PERSONALES

  {
    id: 29,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "POR EL SE APREUBA EL MANUAL DE REGISTRO DE CANDIDATURAS PARA EL PELO 2023-2024",
    titleAnexo1:
      "ANEXO ÚNICO MANUAL PARA EL REGISTRO DE CANDIDATURAS PELO 2023-2024",
    titleAnexo2: "ANEXO 1.1 DEL MANUAL DE REGISTRO",
    titleAnexo3: "ANEXO 1.2 DEL MANUAL DE REGISTRO",
    titleAnexo4: "ANEXO 1.3 DEL MANUAL DE REGISTRO",
    titleAnexo5: "ANEXO 1.4 DEL MANUAL DE REGISTRO",
    titleAnexo6: "ANEXO 2.1 DEL MANUAL DE REGISTRO",
    titleAnexo7: "ANEXO 2.2 DEL MANUAL DE REGISTRO",
    titleAnexo8: "ANEXO 2.3 DEL MANUAL DE REGISTRO",
    titleAnexo9: "ANEXO 2.4 DEL MANUAL DE REGISTRO",
    titleAnexo10: "ANEXO 3.1 DEL MANUAL DE REGISTRO",
    titleAnexo11: "ANEXO 3.2 DEL MANUAL DE REGISTRO",
    titleAnexo12: "ANEXO 3.3 DEL MANUAL DE REGISTRO",
    titleAnexo13: "ANEXO 4.1 DEL MANUAL DE REGISTRO",
    titleAnexo14: "ANEXO 4.2 DEL MANUAL DE REGISTRO",
    titleAnexo15: "ANEXO 4.3 DEL MANUAL DE REGISTRO",
    titleAnexo16: "ANEXO 5 DEL MANUAL DE REGISTRO",
    titleAnexo17: "ANEXO 6.1 DEL MANUAL DE REGISTRO",
    titleAnexo18: "ANEXO 6.2 DEL MANUAL DE REGISTRO",
    titleAnexo19: "ANEXO 6.3 DEL MANUAL DE REGISTRO",
  },
  {
    id: 30,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "POR EL QUE SE RESUELVE RESPECTO DEL CUMPLIMIENTO DE PORCENTAJE DE APOYO DE LA CIUDADANIA A LAS C.I.",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
    titleAnexo3: "ANEXO TRES",
  },
  {
    id: 31,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA CONVOCATORIA CAES Y SE",
    titleAnexo1: "ANEXO ÚNICO CONVOCATORIA SE Y CAE",
  },
  {
    id: 32,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "POR EL QUE SE DA RESPUESTA A DIVERSAS COMUNIDADES NAHUAS Y EQUIPARABLES",
  },
  {
    id: 33,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "POR EL QUE SE APRUEBA LA INTEGRACIÓN DE CONSEJOS DISTRITALES Y MUNICIPALES",
    titleAnexo1: "ANEXO UNO INTEGRACIÓN CONSEJOS DISTRITALES",
    titleAnexo2: "ANEXO DOS INTEGRACIÓN CONSEJOS MUNICIPALES",
    titleAnexo3: "ANEXO TRES LISTA DE RESERVA",
  },
  {
    id: 34,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc:
      "POR EL QUE SE APRUEBA LA SUSTICIONES DE CONSEJOS DISTRITALES ELECTORALES",
  },
  {
    id: 35,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "MAR",
    nameDoc:
      "RESPECTO A LA SOLICITUD DE REGISTRO DEL CONVENIO DE CANDIDATURA COMÚN SIGAMOS HACIENDO HISTORIA EN TLAXCALA",
  },
  {
    id: 36,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA DISTRIBUCIÓN DEL FINANCIAMIENTO PUBLICO",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
  },
  {
    id: 37,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "DICTAMEN DE RESULTADOS SPEN",
    titleAnexo1: "DICTAMEN",
  }, //
  {
    id: 38,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE DESIGNA ENCARGADA DE LA COORDINACIÓN DE ARCHIVOS",
  },
  {
    id: 39,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "INTEGRACIÓN COMISIÓN DE DEBATES",
  },
  {
    id: 40,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA EL PROGRAMA OPERATIVO DE RED DE CANDIDATAS",
    titleAnexo1: "PROGRAMA OPERATIVO DE LA RED DE CANDIDATAS",
  },
  {
    id: 41,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "FECHA  PUBLICACIÓN DEL SISTEMA CONÓCELES",
  },
  {
    id: 42,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SUSTITUCIÓN DE INTEGRANTES DE CONSEJOS DISTRITALES Y MUNICIPALES",
  },
  {
    id: 43,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE DESIGNA AL PERSONAL BODEGAS ELECTORALES",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 44,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "POR EL QUE SE APRUEBA LA DISTRIBUCIÓN DE FINANCIAMIENTO PÚBLICO",
    titleAnexo1: "ANEXO UNICO INFORME MATERIALES DIDÁCTICOS",
    titleAnexo2: "ANEXO DEL INFORME PASO A PASO 4 DE JUNIO",
    titleAnexo3: "ANEXO DEL INFORME PASO A PASO PAQ",
    titleAnexo4: "ANEXO DEL INFORME PASO A PASO REUNION TRABAJO",
    titleAnexo5: "ANEXO DEL INFORME MATERIAL DIDACTICO CUADERNILLO",
  },
  {
    id: 45,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PRD",
  },
  {
    id: 46,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PT",
  },
  {
    id: 47,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PVEM",
  },
  {
    id: 48,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL MC",
  },
  {
    id: 49,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PAC",
  },
  {
    id: 50,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL MORENA",
  },
  {
    id: 51,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PNAT",
  },
  {
    id: 52,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL RSPT",
  },
  {
    id: 53,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL FXMT",
  },
  {
    id: 54,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PAN",
  },
  {
    id: 55,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA LA PLATAFORMA ELECTORAL DEL PRI",
  },
  {
    id: 56,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PAN",
  },
  {
    id: 57,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PRI",
  },
  {
    id: 58,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PRD",
  },
  {
    id: 59,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PT",
  },
  {
    id: 60,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PVEM",
  },
  {
    id: 61,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL MC",
  },
  {
    id: 62,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL PAC",
  },
  {
    id: 63,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL MORENA",
  },
  {
    id: 64,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL NAT",
  },
  {
    id: 65,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL RSPT",
  },
  {
    id: 66,
    typeDoc: "ACUERDO",
    monthDoc: "MAR",
    nameDoc: "SE REGISTRA PROGRAMA DE GOBIERNO DEL FXMT",
  },
  {
    id: 67,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP PRD",
    titleAnexo1: "DICTAMEN PRD",
  },
  {
    id: 68,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP PT",
    titleAnexo1: "DICTAMEN PT",
  },
  {
    id: 69,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP MC",
    titleAnexo1: "DICTAMEN MC",
  },
  {
    id: 70,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP PAC",
    titleAnexo1: "DICTAMEN PAC",
  },
  {
    id: 71,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP PVEM",
    titleAnexo1: "DICTAMEN PVEM",
  },
  {
    id: 72,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP MORENA",
    titleAnexo1: "DICTAMEN MORENA",
  },
  {
    id: 73,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP PNAT",
    titleAnexo1: "DICTAMEN PNAT",
  },
  {
    id: 74,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP RSPT",
    titleAnexo1: "DICTAMEN RSPT",
  },
  {
    id: 75,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP FXMT",
    titleAnexo1: "DICTAMEN FXMT",
  },
  {
    id: 76,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO CANDIDATURA COMUN",
  },
  {
    id: 77,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO COALICION F y C",
    titleAnexo1: "DICTAMEN PAN",
    titleAnexo2: "ANEXO 2",
  },
  {
    id: 78,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO RP PAN",
    titleAnexo1: "DICTAMEN PAN",
  },
  {
    id: 79,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DP RP PRI",
    titleAnexo1: "ANEXO 1",
  },
  {
    id: 80,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCIÓN CONSEJOS MUNICIPALES",
  },
  {
    id: 81,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCIÓN CONSEJOS DISTRITALES Y MUNICIPALES",
  },
  {
    id: 82,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "AMPLIACION DE PLAZO CONVOCATORIA SEL Y CAEL",
    titleAnexo1: "CONVOCATORIA SEL Y CAEL",
  },
  {
    id: 83,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CUMPLIMIENTO DE SENTENCIA DE LA SALA REGIONAL",
    titleAnexo1: "MANUAL REGISTRO CANDIDATURAS",
  },
  {
    id: 84,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SE DA RESPUESTA A PERSONAS",
  },
  {
    id: 85,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "MODIFICACIÓN CONVENIO COALICION F Y C",
  },
  {
    id: 86,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "MEDIDAS DE SEGURIDAD DE BOLETAS",
  },
  {
    id: 87,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "VOTO ANTICIPADO Y BOLETA DUPLEX",
  },
  {
    id: 88,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP PRD",
    titleAnexo1: "DICTAMEN PRD",
  },
  {
    id: 89,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP PT",
  },
  {
    id: 90,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP PAC",
    titleAnexo1: "DICTAMEN PAC",
  },
  {
    id: 91,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCION CONSEJOS MUNICIPALES",
  },
  {
    id: 92,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP MR COALICION F Y C",
  },
  {
    id: 93,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP RP PAN",
    titleAnexo1: "DICTAMEN PAN",
  },
  {
    id: 94,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP PVEM",
    titleAnexo1: "DICTAMEN PVEM",
  },
  {
    id: 95,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP MORENA",
    titleAnexo1: "DICTAMEN MORENA",
  },
  {
    id: 96,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP PNAT",
    titleAnexo1: "DICTAMEN PNAT",
  },
  {
    id: 97,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP RSPT",
    titleAnexo1: "DICTAMEN RSPT",
  },
  {
    id: 98,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REQUERIMIENTO DIP FXMT",
    titleAnexo1: "DICTAMEN FXMT",
  },
  {
    id: 99,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCION CONSEJOS MUNICIPALES",
  },
  {
    id: 100,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP FXMT",
    titleAnexo1: "DICTAMEN FXMT",
  },
  {
    id: 101,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP NAT",
  },
  {
    id: 102,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP RSPT",
    titleAnexo1: "DICTAMEN",
    titleAnexo2: "FE DE ERRATAS",
  },
  {
    id: 103,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP PVEM",
  },
  {
    id: 104,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP MORENA",
  },
  {
    id: 105,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO CANDIDATURA COMUN",
  },
  {
    id: 106,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP MR COALICION",
  },
  {
    id: 107,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "REGISTRO DIP RP PAN",
  },
  {
    id: 108,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCIONES CONSEJOS MUNICIPALES",
  },
  {
    id: 109,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO DIP PT",
  },
  {
    id: 110,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CONVOCATORIA CAES Y SE",
    titleAnexo1: "CONVOCATORIA SE Y CAE",
  },
  {
    id: 111,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "INCLUSION DE SOBRENOMBRES",
  },
  {
    id: 112,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CONVOCATORIA DIFUSORES DEL PREP",
    titleAnexo1: "CARTA DE INTENCIÓN PREP",
    titleAnexo2: "CONVOCATORIA DIFUSORES OFICIALES",
    titleAnexo3: "LISTA DE VERIFICACIÓN DE REQUISITOS DIFUSORES",
  },
  {
    id: 113,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "READECUACIÓN DE PRESUPUESTO",
    titleAnexo1: "ADECUACIÓN PRESUPUESTAL",
  },
  {
    id: 114,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-043-2024",
  },
  {
    id: 115,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JE-042-2024",
  },
  {
    id: 116,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD REGISTRO DIP COALICION F Y C",
    titleAnexo1: "ANEXO POSTULACIONES DIP COALICION F Y C",
  },
  {
    id: 117,
    typeDoc: "ACUERDO",
    monthDoc: "ABR",
    nameDoc: "REFORMA DE LINEAMIENTOS DE DEBATES 2024",
    titleAnexo1: "ANEXO UNICO REFORMA LINEAMIENTOS DE DEBATES 2024",
  },
  {
    id: 118,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SUSTITUCIONES DIP PT Y FXMT",
    titleAnexo1: "DICTAMEN SUST DIP PT Y FXMT",
  },
  {
    id: 119,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO CANDIDATURAS INDPENDIENTES",
  },
  {
    id: 120,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRD",
    titleAnexo1: "ANEXO UNO POSTULACIONES PRD",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PRD",
    titleAnexo3: "DICTAMEN PRD AYU",
  },
  {
    id: 121,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PT",
    titleAnexo3: "DICTAMEN PT AYU",
  },
  {
    id: 122,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PVEM",
    titleAnexo1: "ANEXO UNO POSTULACIONES PVEM",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  PVEM",
    titleAnexo3: "DICTAMEN PVEM AYU",
  },
  {
    id: 123,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MC",
    titleAnexo1: "ANEXO UNO POSTULACIONES MC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  MC",
    titleAnexo3: "DICTAMEN MC AYU",
  },
  {
    id: 124,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SE RESUELVE REGISTRO AYUNTAMIENTOS MORENA",
    titleAnexo1: "ANEXO UNO POSTULACIONES MORENA",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION MORENA",
    titleAnexo3: "DICTAMEN MORENA AYU",
  },
  {
    id: 125,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO NAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES NAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION NAT",
    titleAnexo3: "DICTAMEN  PNAT AYU",
  },
  {
    id: 126,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PAC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PAC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PAC",
    titleAnexo3: "DICTAMEN PAC AYU",
  },
  {
    id: 127,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO FMXT",
    titleAnexo1: "ANEXO UNO POSTULACION FMXT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION FMXT",
    titleAnexo3: "DICTAMEN FXM AYU",
  },
  {
    id: 128,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION RSPT",
    titleAnexo3: "DICTAMEN RSP AYU",
  },
  {
    id: 129,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PAN",
    titleAnexo1: "ANEXO UNO POSTULACIONES PAN",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION  PAN",
    titleAnexo3: "DICTAMEN PAN AYU",
  },
  {
    id: 130,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PRI",
    titleAnexo3: "DICTAMEN PRI AYU",
  },
  {
    id: 131,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO COALICION F Y C",
    titleAnexo1: "ANEXO UNO POSTULACIONES COALICION",
  },
  {
    id: 132,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PRD",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
    titleAnexo3: "DICTAMEN PRD AYU",
  },
  {
    id: 133,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PVEM",
    titleAnexo3: "DICTAMEN PT COM",
  },
  {
    id: 134,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PVEM",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PVEM",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
    titleAnexo3: "DICTAMEN PVEM COM",
  },
  {
    id: 135,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC MC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MORENA",
    titleAnexo3: "DICTAMEN MC COM",
  },
  {
    id: 136,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC MORENA",
    titleAnexo1: "ANEXO UNO POSTULACION PC MORENA",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC NAT",
    titleAnexo3: "DICTAMEN MORENA COM",
  },
  {
    id: 137,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC NAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC NAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
    titleAnexo3: "DICTAMEN PNAT COM",
  },
  {
    id: 138,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PAC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
    titleAnexo3: "DICTAMEN PAC COM",
  },
  {
    id: 139,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC FMXT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
    titleAnexo3: "DICTAMEN FXM COM",
  },
  {
    id: 140,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE COTACION PC PAN",
    titleAnexo3: "DICTAMEN RSP COM",
  },
  {
    id: 141,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PAN",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
    titleAnexo3: "DICTAMEN PAN COM",
  },
  {
    id: 142,
    typeDoc: "RESOLUCION",
    monthDoc: "ABR",
    nameDoc: "SOLICITUD DE REGISTRO PC PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
    titleAnexo3: "DICTAMEN PRI COM",
  },
  // falta el acuerdo 143
  {
    id: 144,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO DIP RP PRI",
    titleAnexo1: "ANEXO ÚNICO DIP RP PRI",
  },
  {
    id: 145,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc:
      "SUSTITUCIONES DIP FXMT, PAC Y COALICIÓN FUERZA Y CORAZÓN POR TLAXCALA",
  },
  {
    id: 146,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SOBRENOMBRES CANDIDATURAS",
  },
  {
    id: 147,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MC",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS MC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS MC",
    titleAnexo3: "DICTAMEN MC AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 148,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS MORENA",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS MORENA",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS MORENA",
    titleAnexo3: "DICTAMEN MORENA AYUNTAMIENTOS",
    titleAnexo4: "ANEXO CUATRO SOBRENOMBRES MORENA",
    titleAnexo5: "FE DE ERRATAS",
  },
  {
    id: 149,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD DE REGISTRO AYUNTAMIENTOS PAC",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PAC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PAC",
    titleAnexo3: "DICTAMEN PAC AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 150,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PT",
    titleAnexo3: "DICTAMEN PT AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 151,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRD",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRD",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRD",
    titleAnexo3: "DICTAMEN PRD AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 152,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS RSPT",
    titleAnexo3: "DICTAMEN RSPT AYUNTAMIENTOS",
  },
  {
    id: 153,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS FXMT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS FXMT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS FXMT",
    titleAnexo3: "DICTAMEN FXMT AYUNTAMIENTOS",
  },
  {
    id: 154,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS NAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS NAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS NAT",
    titleAnexo3: "DICTAMEN NAT AYUNTAMIENTOS",
  },
  {
    id: 155,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRI",
    titleAnexo3: "DICTAMEN PRI AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 156,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTOS PAN",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PAN",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PAN",
    titleAnexo3: "DICTAMEN PAN AYUNTAMIENTOS",
  },
  {
    id: 157,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO INDEPENDIENTES",
  },
  {
    id: 158,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO AYUNTAMIENTO PVEM",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PVEM",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PVEM",
    titleAnexo3: "DICTAMEN PVEM AYUNTAMIENTOS",
  },
  {
    id: 159,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLCITUD DE REGISTRO AYUNTAMIENTO COALICION",
    titleAnexo1: "VOTO CONCURRENTE CONSEJERA LICDA. ERIKA PERIAÑEZ RODRIGUEZ",
    titleAnexo2: "VOTO CONCURRENTE CONSEJERO MTRO. JUAN CARLOS MINOR MÁRQUEZ",
    titleAnexo3: "POSTULACIONES AYUNTAMIENTOS  COALICION",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 160,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS RSPT",
    titleAnexo3: "DICTAMEN RSPT AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 161,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS PRI",
    titleAnexo3: "DICTAMEN PRI AYUNTAMIENTOS",
  },
  {
    id: 162,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS FXMT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS FXMT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS FXMT",
    titleAnexo3: "DICTAMEN FXMT AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 163,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO AYUNTAMIENTOS NAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES AYUNTAMIENTOS NAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION AYUNTAMIENTOS NAT",
    titleAnexo3: "DICTAMEN NAT AYUNTAMIENTOS",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 164,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "FINANCIAMIENTO PÚBLICO PARA CI",
  },
  {
    id: 165,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCIONES MC Y PT",
  },
  {
    id: 166,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
  },
  {
    id: 167,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PRD",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
    titleAnexo2: "ANEXO DOS POSTULACIONES PC PRD",
    titleAnexo3: "DICTAMEN PRD AYUNTAMIENTOS",
  },
  {
    id: 168,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC FXMT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
  },
  {
    id: 169,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PAC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
  },
  {
    id: 170,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC MC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
    titleAnexo3: "DICTAMEN MC PC",
  },
  {
    id: 171,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
    titleAnexo3: "DICTAMEN PRI PC",
  },
  {
    id: 172,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
  },
  {
    id: 173,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PVEM",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PVEM",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PVEM",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 174,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC MORENA",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC MORENA",
    titleAnexo2: "ANEXO DOS POSTULACIONES PC MORENA",
    titleAnexo3: "DICTAMEN MORENA AYUNTAMIENTOS",
  },
  {
    id: 175,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PAN",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAN",
  },
  {
    id: 176,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "REQUERIMIENTO SOLICITUD REGISTRO PC PNAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PNAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PNAT",
  },
  {
    id: 177,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLCITUD DE REGISTRO PC RSPT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC RSPT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC RSPT",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 178,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC PAN",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAN",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAN",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 179,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC PAC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PAC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PAC",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 180,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC PRD",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRD",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRD",
  },
  {
    id: 181,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD REGISTRO PC PT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PT",
    titleAnexo3: "DICTAMEN PT PC",
    titleAnexo4: "FE DE ERRATAS",
  },
  {
    id: 182,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "RESOLUCION SOLICITUD DE REGISTRO PC PRI",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC PRI",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC PRI",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 183,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC MC",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC MC",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MC",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 184,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC MORENA",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC MORENA",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC MORENA",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 185,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC FXMT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC FXMT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC FXMT",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 186,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOLICITUD DE REGISTRO PC NAT",
    titleAnexo1: "ANEXO UNO POSTULACIONES PC NAT",
    titleAnexo2: "ANEXO DOS PORCENTAJE DE VOTACION PC NAT",
    titleAnexo3: "FE DE ERRATAS",
  },
  {
    id: 187,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "CONVOCATORIA PERMANENTE SEL CAEL",
    titleAnexo1: "CONVOCATORIA",
  },
  {
    id: 188,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCION CONSEJOS DISTRATALES Y MUNICIPALES",
  },
  {
    id: 189,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "PERSONAL DESIGNADO PARA SELLADO",
    titleAnexo1: "ANEXO UNICO, PERSONAL PARA SELLADO",
  },
  {
    id: 190,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SOBRENOMBRES",
  },
  {
    id: 191,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCION MC Y PRD",
  },
  {
    id: 192,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCION MORENA",
  },
  {
    id: 193,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCIONES MORENA Y MC",
  },
  {
    id: 194,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "CUMPLIMIENTO SENTENCIA TET-JE-095-2024. PRD",
  },
  {
    id: 195,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "PRONUNCIAMIENTO ACCION AFIRMATIVA MIGRANTES",
  },
  {
    id: 196,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCIONES PT Y PAC",
  },
  {
    id: 197,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SOBRENOMBRES",
  },
  {
    id: 198,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCION CONSEJOS D Y M",
  },
  {
    id: 199,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SE DA RESPUESTA AL CIUDADANO BERNARDO RODRIGUEZ",
  },
  {
    id: 200,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCIONES FxMT Y PAN",
  },
  {
    id: 201,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "SE MODIFICA PTO DEL PREP",
    titleAnexo1: "ANEXO ÚNICO PTO DEL PREP",
  },
  {
    id: 202,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "DESIGNACION DE LAS CASILLAS Y PROCEDIMIENTOS DE VERIFICACIÓN",
    titleAnexo1: "ANEXO UNO SELECCIÓN DE CASILLAS",
    titleAnexo2:
      "ANEXO DOS PROCEDIMIENTOS PARA LA VERIFICACIÓN DE LAS MEDIDAS DE SEGURIDAD",
  },
  {
    id: 203,
    typeDoc: "RESOLUCION",
    monthDoc: "MAY",
    nameDoc: "SUSTITUCIONES FXMT PT Y PAC",
    titleAnexo1: "DICTAMEN PT 3RA REGIDURIA SAN JOSÉ TEACALCO",
  },
  {
    id: 205,
    typeDoc: "ACUERDO",
    monthDoc: "MAY",
    nameDoc: "RESPUESTA AL REPRESENTANTE SUPLENTE DE MORENA",
  },
  {
    id: 206,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "DESIGNACION PERSONAL DEL ITE COMO CAEL",
    titleAnexo1: "DESIGNACION PERSONAL DEL ITE COMO CAEL",
  },
  {
    id: 207,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "SUSTITUCION DE CONSEJOS MUNICIPALES",
  },

  {
    id: 208,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO DE SEDE CONSEJOS MUNICIPALES",
  },

  {
    id: 209,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "ASUME ATRIBUCIONES DEL CM SAN LUCAS TECOPILCO",
  },

  {
    id: 210,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "SUSTITUCION DE CONSEJOS MUNICIPALES",
  },

  {
    id: 211,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO DE SEDE CONSEJO MUNICIPAL TOTOLAC",
  },

  {
    id: 212,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO DE SEDE DE CONSEJO MUNICIPAL CHIAUTEMPAN",
  },

  {
    id: 213,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO DE SEDE CONSEJO HUAMANTLA",
  },

  {
    id: 214,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "SUSTITUCION DE INTEGRANTES CONSEJO MUNICIPAL HUAMANTLA",
  },

  {
    id: 215,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO SEDE CM SANTA CRUZ QUILEHTLA",
  },

  {
    id: 216,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "CAMBIO DE SEDE CM CALPULALPAN",
  },

  {
    id: 217,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "ASUME ATRIBUCIONES DEL CM AXOCOMANITLA E IXTENCO",
  },

  {
    id: 218,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "SUSTITUCION CM CALPULALPAN",
  },

  {
    id: 219,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "COMPUTO MUNICIPAL DE IXTENCO",
  },
  {
    id: 220,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "COMPUTO MUNICIPAL SAN LORENZO AXOCOMANITLA",
  },
  {
    id: 221,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "COMPUTO MUNICIPAL SAN LUCAS TECOPILCO",
  },
  {
    id: 222,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "OBTENCIÓN DEL 3 PORCIENTO DE LA VOTACIÓN",
  },
  {
    id: 223,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "ASIGNACION DIPTACIONES RP",
  },
  {
    id: 224,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "ASIGNACION REGIDURIAS",
    titleAnexo1: "1. Tlaxcala",
    titleAnexo2: "2. Huamantla",
    titleAnexo3: "3. San Pablo del Monte",
    titleAnexo4: "4. Apizaco",
    titleAnexo5: "5. Chiautempan",
    titleAnexo6: "6. Calpulalpan",
    titleAnexo7: "7. Zacatelco",
    titleAnexo8: "8. Tlaxco",
    titleAnexo9: "9. Yauhquemehcan",
    titleAnexo10: "10. Ixtacuixtla",
    titleAnexo11: "11. Contla de Juan Cuamatzi",
    titleAnexo12: "12. Tetla de la Solidaridad",
    titleAnexo13: "13. Papalotla de Xicohtencatl",
    titleAnexo14: "14. Panotla",
    titleAnexo15: "15. Nativitas",
    titleAnexo16: "16. Xaloztoc",
    titleAnexo17: "17. Teolocholco",
    titleAnexo18: "18. Santa Cruz Tlaxcala",
    titleAnexo19: "19. Totolac",
    titleAnexo20: "20. Tepetitla",
    titleAnexo21: "21. La Magdalena Tlatelulco",
    titleAnexo22: "22. Nanacamilpa de Mariano Matamoros",
    titleAnexo23: "23. Atltzayanca",
    titleAnexo24: "24. Tzompantepec",
    titleAnexo25: "25. El Carmen Tequextitla",
    titleAnexo26: "26. Cuapiaxtla",
    titleAnexo27: "27. Apetatitlán de Antonio Carvajal",
    titleAnexo28: "28. Terrenate",
    titleAnexo29: "29. Hueyotlipan",
    titleAnexo30: "30. Xicohtzinco",
    titleAnexo31: "31. Tetlatlahuca",
    titleAnexo32: "32. Tepeyanco",
    titleAnexo33: "33. Tenancigo",
    titleAnexo34: "34. San Francisco Tetlanohcan",
    titleAnexo35: "35. Mazatecochco de José María Morelos",
    titleAnexo36: "36. Amaxac de Guerrero",
    titleAnexo37: "37. Xaltocan",
    titleAnexo38: "38. Santa Catarina Ayometla",
    titleAnexo39: "39. Sanctorum de Lázaro Cardenas",
    titleAnexo40: "40. Españita",
    titleAnexo41: "41. Zitlaltepec",
    titleAnexo42: "42. Santa Ana Nopalucan",
    titleAnexo43: "43. Santa Cruz Quilehtla",
    titleAnexo44: "44. San Juan Huactzinco",
    titleAnexo45: "45. Ixtenco",
    titleAnexo46: "46. Atlangatepec",
    titleAnexo47: "47. San José Teacalco",
    titleAnexo48: "48. Acuamanala de Miguel Hidalgo",
    titleAnexo49: "49. Tocatlan",
    titleAnexo50: "50. Benito Juárez",
    titleAnexo51: "51. Cuaxomulco",
    titleAnexo52: "52. San Damian Texoloc",
    titleAnexo53: "53. San Lorenzo Axocomanitla",
    titleAnexo54: "54. Santa Isabel Xiloxoxtla",
    titleAnexo55: "55. Emiliano Zapata",
    titleAnexo56: "56. Muñoz de Domingo Arenas",
    titleAnexo57: "57. Santa Apolonia Teacalco",
    titleAnexo58: "58. San Jeronimo Zacualpan",
    titleAnexo59: "59. Lázaro Cárdenas",
    titleAnexo60: "60. San Lucas Tecopilco",
  },
  {
    id: 225,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "POR EL QUE SE CALENDARIZAN MULTAS",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 226,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "SE READECUA PRESUEPUESTO",
    titleAnexo1: "ANEXO ÚNICO",
  },
  {
    id: 227,
    typeDoc: "ACUERDO",
    monthDoc: "JUN",
    nameDoc: "RETIRO DE PROPAGANDA",
    titleAnexo1: "ANEXO ÚNICO",
    titleAnexo2: "ANEXO ÚNICO",
  },
  {
    id: 228,
    typeDoc: "ACUERDO",
    monthDoc: "JUL",
    nameDoc: "DESIGNACION PERSONAL RECUENTO SANTA CRUZ TECHACHALCO PANOTLA",
  },
  {
    id: 229,
    typeDoc: "ACUERDO",
    monthDoc: "JUL",
    nameDoc: "DESIGNACION PERSONAL RECUENTO EMILIANO ZAPATA PANOTLA",
  },
  {
    id: 230,
    typeDoc: "ACUERDO",
    monthDoc: "JUL",
    nameDoc: "CUMPLIMIENTO SENTENCIA TET-JE-190-2024 ASIGNACION DIPUTACIONES",
  },
  {
    id: 231,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc: "DESIGNACION PERSONAL RECUENTO SAN BUENAVENTURA ATEMPAN",
  },
  {
    id: 232,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JDC-223-2024 Y ACUMULADOS",
    titleAnexo1: "ACUAMANALA DE MIGUEL HIDALGO",
    titleAnexo2: "APIZACO",
    titleAnexo3: "TOTOLAC",
    titleAnexo4: "YAUHQUEMEHCAN",
    titleAnexo5: "TZOMPANTEPEC",
    titleAnexo6: "SANCTORUM DE LAZARO CARDENAS",
  },
  {
    id: 233,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc: "SE ATIENDE ESCRITO DE REPRESENTANTE SUPLENTE PAC",
  },
  {
    id: 234,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc: "CUMPLIMIENTO DE SENTENCIA TET-JDC-324-2024",
    titleAnexo1: "ANEXO 1. APIZACO",
    titleAnexo2: "ANEXO 2. YAUHQUEMEHCAN",
  },
  {
    id: 235,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc:
      "CUMPLIMIENTO DE SENTENCIA SCM-JDC-2142-2024 TZOMPANTEPEC Y XICOHTZINCO",
    titleAnexo1: "ANEXO XICOHTZINCO",
  },
  {
    id: 236,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc:
      "INTEGRACION DE LA LXV LEGISLATURA DEL CONGRESO DEL ESTADO DE TLAXCALA",
  },
  {
    id: 237,
    typeDoc: "ACUERDO",
    monthDoc: "AGO",
    nameDoc: "SE MODIFICA PROCEDIMIENTO PLAZOS DE RETIRO PROPAGANDA",
    titleAnexo1: "ANEXO ÚNICO PROCEDIMIENTO AMPLIACIÓN PLAZOS",
  },
  {
    id: 238,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "CALENDARIO PELE 2024",
    titleAnexo1: "ANEXO UNICO CALENDARIO LEGAL PELE 2024",
  },
  {
    id: 239,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "CONVOCATORIA PARA OBSERVADORES ELECTORALES PELE 2024",
    titleAnexo1: "ANEXO UNICO CONVOCATORIA OE PELE 2024",
  },
  {
    id: 240,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "PRORROGA DE ACUERDOS PELE 2024",
    titleAnexo1:
      "ANEXO UNO METODOLOGÍA PARA EL MONITOREO DE MEDIOS DE COMUNICACIÓN MASIVA IMPRESOS Y DIGITALES",
    titleAnexo2:
      "ANEXO DOS METODOLOGÍA PARA MONITOREO DE ESPACIOS QUE DIFUNDEN NOTICIAS EN RADIO Y TELEVISIÓN",
    titleAnexo3:
      "ANEXO TRES METODOLOGÍA PARA EL MONITOREO CON PERSPECTIVA DE GÉNERO PELE 2024",
  },
  {
    id: 241,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "PRESUPUESTO 2025",
    titleAnexo1: "ANEXO UNO",
    titleAnexo2: "ANEXO DOS",
    titleAnexo3: "ANEXO TRES",
  },
  {
    id: 242,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "DICTAMEN INCENTIVOS SPEN 2024",
    titleAnexo1: "ANEXO UNICO DICTAMENTE OTORGAMIENTO INCENTIVOS",
  },
  {
    id: 243,
    typeDoc: "ACUERDO",
    monthDoc: "SEP",
    nameDoc: "INFORME DEL SISTEMA CONÓCELES",
    titleAnexo1: "ANEXO UNICO INFORME DEL SISTEMA CONÓCELES",
    titleAnexo2: "FE DE ERRATAS ACUERDO ITE-CG 243-2024",
  },
  {
    id: 244,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "PERDIDA DE REGISTRO PRD",
  },
  {
    id: 245,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "VIGENCIA DE ACUERDOS PARA PELE 2024",
    titleAnexo1: "LINEAMIENTOS PARA EL REGISTRO DE CANDIDATURAS 2024 PELE",
  },
  {
    id: 246,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "ATRIBUCIONES Y FACULTADES DE LOS C M PELE 2024",
  },
  {
    id: 247,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "ADECUACION DE COMISIONES Y JGE",
  },
  {
    id: 248,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "APOYO CON UN TERCERO PARA EL PREP DEL PELE 2024",
  },
  {
    id: 249,
    typeDoc: "RESOLUCIÓN",
    monthDoc: "OCT",
    nameDoc: "EXP POS CQD-Q-GJBR-CG-005-2024",
  },
  {
    id: 250,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "MANUAL DE REGISTRO PELE 2024",
    titleAnexo1: "ANEXO ÚNICO MANUAL DE REGISTRO PELE 2024",
    titleAnexo2: "FORMATO 1.1 SOLICITUD DE REGISTRO INTEGRANTES AYUNTAMIENTO",
    titleAnexo3: "FORMATO 1.2 SOLICITUD REGISTRO PRESIDENCIA DE COMUNIDAD",
    titleAnexo4: "FORMATO 2.1 CONSTANCIA DE ACEPTACIÓN POSTULACION  IA",
    titleAnexo5: "FORMATO 2.2 CONSTANCIA DE ACEPTACION PC",
    titleAnexo6: "FORMATO 3.1 MANIFESTACIÓN BAJO PROTESTA IA",
    titleAnexo7: "FORMATO 3.2 MANIFESTACION BAJO PROTESTA PC",
    titleAnexo8: "FORMATO 4.1 ESCRITO DE NORMA ESTATUTARIA IA",
    titleAnexo9: "FORMATO 4.2 ESCRITO NORMA ESTATUTARIA PC",
    titleAnexo10: "FORMATO 5.1 ESCRITO BAJO PROTESTA ART 38 F. VII IA",
    titleAnexo11: "FORMATO 5.2 ESCRITO BAJO PROTESTA ART 38 F. VII PC",
  },
  {
    id: 251,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "BODEGA ELECTORAL",
    titleAnexo1: "BODEGA ELECTORAL",
  },
  {
    id: 252,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "RESPUESTA A SOLICITUD",
  },
  {
    id: 253,
    typeDoc: "ACUERDO",
    monthDoc: "OCT",
    nameDoc: "DICTAMEN PREP PELE 2024 COTAPREP",
    titleAnexo1: "ANEXO ÚNICO DICTAMEN CSSI",
  },
];

// ------------ A C U E R D O S    I N E ------------
export const dataAcuerdosINE2024 = [
  {
    id: 1,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG27/2024",
    monthDoc: "ENE",
    nameDoc:
      "SE APRUEBAN LAS CONVOCATORIAS PARA LA SELECCIÓN Y DESIGNACIÓN DE LA CONSEJERA PRESIDENTA DEL OPL DE CHIAPAS ASÍ COMO DE LAS CONSEJERAS O CONSEJEROS ELECTORALES DE LOS OPL DE LAS ENTIDADES DE BAJA CALIFORNIA SUR, CAMPECHE, CIUDAD DE MÉXICO, COLIMA, ESTADO DE MÉXICO, GUANAJUATO, GUERRERO, JALISCO, MICHOACAN, MORELOS, NUEVO LEÓN, OAXACA, QUERÉTARO, SAN LUIS POTOSÍ, SONORA, TABASCO, TLAXCALA, VERACRUZ, YUCATAN Y ZACATECAS",
    titleAnexoPDF1: "Punto 4 Convocatoria Baja California Sur 2024",
    titleAnexoPDF2: "Punto 4 Convocatoria Campeche 2024",
    titleAnexoPDF3: "Punto 4 Convocatoria CDMX 2024",
    titleAnexoPDF4: "Punto 4 Convocatoria Chiapas 2024",
    titleAnexoPDF5: "Punto 4 Convocatoria Colima 2024",
    titleAnexoPDF6: "Punto 4 Convocatoria Estado de México 2024",
    titleAnexoPDF7: "Punto 4 Convocatoria Guanajuato 2024",
    titleAnexoPDF8: "Punto 4 Convocatoria Guerrero 2024",
    titleAnexoPDF9: "Punto 4 Convocatoria Jalisco 2024",
    titleAnexoPDF10: "Punto 4 Convocatoria Michoacán 2024",
    titleAnexoPDF11: "Punto 4 Convocatoria Morelos 2024",
    titleAnexoPDF12: "Punto 4 Convocatoria Nuevo León 2024",
    titleAnexoPDF13: "Punto 4 Convocatoria Oaxaca 2024",
    titleAnexoPDF14: "Punto 4 Convocatoria Querétaro 2024",
    titleAnexoPDF15: "Punto 4 Convocatoria San Luis Potosí 2024",
    titleAnexoPDF16: "Punto 4 Convocatoria Sonora 2024",
    titleAnexoPDF17: "Punto 4 Convocatoria Tabasco 2024",
    titleAnexoPDF18: "Punto 4 Convocatoria Tlaxcala 2024",
    titleAnexoPDF19: "Punto 4 Convocatoria Veracruz 2024",
    titleAnexoPDF20: "Punto 4 Convocatoria Yucatán 2024",
    titleAnexoPDF21: "Punto 4 Convocatoria Zacatecas 2024",
  },
  {
    id: 2,
    typeDoc: "ACUERDO",
    numDoc: "A09/INE/TLAX/JD03/13-05-2024",
    nameDoc: "SE APRUEBAN LOS AJUSTES A LA UBICACIÓN DE CASILLA ELECTORAL",
  },
  {
    id: 3,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG2243/2024",
    nameDoc:
      "DESIGNACION DE LAS PRESIDENCIAS  DE LOS OPL DE CAMPECHE Y CHIAPAS Y DESIGNACION DE CONSEJERÍAS DE LOS OPL DE BAJA CALIFORNIA SUR, CAMPECHE, CIUDAD DE MÉXICO, COLIMA, ESTADO DE MÉXICO, GUANAJUATO, GUERRERO, JALISCO, MICHOACÁN, MORELOS, NUEVO LEÓN, OAXACA, QUERÉTARO, SAN LUIS POTOSÍ, SONORA, TABASCO, TLAXCALA, VERACRUZ, YUCATÁN Y ZACATECAS",
    titleAnexoPDF1: "DICTAMEN BCS 2024",
    titleAnexoPDF2: "DICTAMEN CAM 2024",
    titleAnexoPDF3: "DICTAMEN CDMX 2024",
    titleAnexoPDF4: "DICTAMEN CHIA 2024",
    titleAnexoPDF5: "DICTAMEN COL 2024",
    titleAnexoPDF6: "DICTAMEN GRO 2024",
    titleAnexoPDF7: "DICTAMEN GTO 2024",
    titleAnexoPDF8: "DICTAMEN JAL 2024",
    titleAnexoPDF9: "DICTAMEN MEX 2024",
    titleAnexoPDF10: "DICTAMEN MICH 2024",
    titleAnexoPDF11: "DICTAMEN MOR 2024",
    titleAnexoPDF12: "DICTAMEN NL 2024",
    titleAnexoPDF13: "DICTAMEN OAX 2024",
    titleAnexoPDF14: "DICTAMEN QRO 2024",
    titleAnexoPDF15: "DICTAMEN SLP 2024",
    titleAnexoPDF16: "DICTAMEN SON 2024",
    titleAnexoPDF17: "DICTAMEN TAB 2024",
    titleAnexoPDF18: "DICTAMEN TLAX 2024",
    titleAnexoPDF19: "DICTAMEN VER 2024",
    titleAnexoPDF20: "DICTAMEN YUC 2024",
    titleAnexoPDF21: "DICTAMEN ZAC 2024",
  },
  {
    id: 4,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG2267/2024",
    nameDoc:
      "PLAN INTEGRAL Y CALENDARIO DE COORDINACIÓN PARA LOS PELE 2024 EN LOS ESTADOS DE CHIHUAHUA, HIDALGO, MICHOACÁN, TLACALA Y YUCATÁN",
  },
  {
    id: 5,
    typeDoc: "ACUERDO",
    numDoc: "INE/CG2269/2024",
    nameDoc:
      "SE ORDENA LA PUBLICACIÓN DEL CATÁLOGO DE EMISORAS PARA EL PEE Y SE MODIFICAN LOS ACUERDOS INE/JGE75/2024 E INE/ACRT/30/2024",
    titleAnexoEXCEL1: "Anexo 1 Catálogo PEX San Lucas Tecopilco Tlaxcala 2024",
    titleAnexoEXCEL2: "Modelo de autoridades PEX Tlaxcala 2024",
    titleAnexoEXCEL3: "1 Precampaña PEX TLAXCALA 2024",
    titleAnexoEXCEL4: "2 Intercampaña PEX TLAXCALA 2024",
    titleAnexoEXCEL5: "A 3.1 Campaña PEX TLAXCALA 2024-0 CI",
    titleAnexoEXCEL6: "B 3.2 Campaña PEX TLAXCALA 2024-1 CI",
    titleAnexoEXCEL7: "C 3.3 Campaña PEX TLAXCALA 2024-2 CI",
    titleAnexoEXCEL8: "TLAX 24 01 PEX A Radio",
    titleAnexoEXCEL9: "TLAX 24 01 PEX A Televisión",
    titleAnexoEXCEL10: "TLAX 24 01 PEX B Radio",
    titleAnexoEXCEL11: "TLAX 24 01 PEX B Televisión",
    titleAnexoEXCEL12: "TLAX 24 01 PEX C Radio",
    titleAnexoEXCEL13: "TLAX 24 01 PEX C Televisión",
  },
];
